import React, { useContext, useEffect } from "react";
import ThumbLike from "../../assets/images/confetti.gif";
import Button from "../../components/button";
import { StepperContext } from ".";

const Step8 = ({ setOpen, responseQuestions }) => {
  const { storeQuestionArray, setStepperData, stepperData } =
    useContext(StepperContext);
  function openValue() {
    setOpen(false);
  }

  const familyMembers = storeQuestionArray?.find((memeber) => memeber?.member);
  const familyIncome = storeQuestionArray?.find((income) => income?.income);
  const familyHeight =
    stepperData?.feetInchesValue.feet.value * 30.48 +
    stepperData?.feetInchesValue.inches.value * 2.54;
  const familyWeight = stepperData?.userWeight;
  // live ur home and income
  function calculateHHI(data) {
    let calData = { base: 14580, factor: 5140 };
    let o = (data.members - 1) * calData.factor + calData.base;
    let l = Math.floor((100 * data.annualIncome) / o);
    return l;
  }

  const bmiCalculator = (data) => {
    let heightvalue=data?.height 
   const bmi=(data?.weight / (heightvalue * heightvalue) * 703).toFixed(1)
    return +bmi;
  };
  const BMICalculation = bmiCalculator({
    height: +familyHeight,
    weight: +familyWeight,
  });
  const HHHCalculation = calculateHHI({
    members: familyMembers?.member,
    annualIncome: familyIncome?.income * 12,
  });

  const updatedStoreQuestionnarieArray = storeQuestionArray?.map((value) => {
    // const valueFind = storeQuestionArray?.find(
    //   (findValue) => findValue?.type === "step4"
    // );
    return {
      answer: value?.answer ?? true,
      // answerkey: valueFind?.answerKey,
    };
  });

  // responseQuestions
  const filterResponse = responseQuestions.filter(
    (response) => response.riskFactor && response?.enrollQuestion?.length
  );
  // Risk Factors Questions

  const riskFactorQuestions = filterResponse?.map((riskValue) => {
    return riskValue?.answer ?? true;
  });

  const riskFactorUpdatedValue = riskFactorQuestions?.some((risk) => risk);

  // MatchAnswers
  let countAnswers = 0;
  const showDivOrNot = updatedStoreQuestionnarieArray?.map((match) => {
    if (
      // match?.answerkey &&
      (riskFactorUpdatedValue || BMICalculation > 25) &&
      HHHCalculation < 250
    ) {
      return (countAnswers = countAnswers + 1);
    } else {
      return (countAnswers = countAnswers);
    }
  });

  const countStepFifth = countAnswers === showDivOrNot?.length;
  useEffect(() => {
    setStepperData((old) => ({ ...old, countStepFifth }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countStepFifth, countAnswers]);
  const value = (
    <div className="text-center">
      <img
        className="mx-auto w-[220px] pb-2"
        src={ThumbLike}
        alt="thumbLikeImage"
      />
      <h2 className="heading2 step-question !pb-0">
        Great News.</h2><h2 className="heading step-question !pb-0">You Qualify to Enroll into WISEWOMAN
      </h2>

      {/* <p className="text-dimGray pt-4 md:px-8 md:!text-[16px] pb-4 paragraph">
        The Indiana Well-Integrated Screening and Evaluation for Women Across
        the Nation (IN-WISEWOMAN) Program addresses the burden of cardiovascular
        disease by identifying and reducing participants' risk of having a heart
        attack, stroke and chronic disease through education and early detection
        screening.
      </p> */}
    </div>
  );
  const notMatchedAnswersText = (
    <div className="text-center">
      <p className="text-textColor text-lg font-medium pb-4">
        Based on the information you provided, you currently do not meet the
        qualifications for WISEWOMAN. However, the state has a number of
        programs available that may be able to provide health services at no
        cost to you. For more information, please
        <a
          href="#contact"
          className="bg-sky-600 underline text-fountainBlue pl-1"
          onClick={() => openValue()}
        >
          leave us a message.
        </a>
      </p>
      <Button text="Close" onClick={() => openValue()} />
    </div>
  );
  return (
    <>{countAnswers === showDivOrNot?.length ? value : notMatchedAnswersText}</>
  );
};

export default Step8;

import React, { useContext } from "react";
import Button from "../../components/button";
import { StepperContext } from ".";
import moment from "moment";

const Step13 = ({ setOpen }) => {
  const { stepperData } = useContext(StepperContext);

  function openValue() {
    setOpen(false);
  }
  const unixToDate = (date) => {
    return moment(date).format("MMMM Do YYYY");
  };
 
  return (
    <>
      <div className="w-full text-[#989898] text-center px-6">
        <h2 className="text-[#595959] text-4xl font-bold pb-7 text-center max-w-lg mx-auto">
          Thank You
        </h2>
        <div className="pb-2 font-semibold text-[17px]">
          <p className="text-[17px]">Your Appointment has been booked for</p>
          <span className="text-fountainBlue">
            {unixToDate(stepperData?.valueAdded)}
          </span>
          with
          <span className="text-fountainBlue">
            {stepperData?.partnerValue?.companyName}
          </span>
        </div>
        <p className="font-semibold pb-4 text-[16px]">
          <span className="font-normal">Location:</span>
          {stepperData?.partnerValue?.location[0]?.address?.text}
        </p>
        <p className="font-semibold pb-4 text-[16px]">
          <span className="font-normal">Phone Number:</span>
          {stepperData?.partnerValue?.partnerPointOfContact?.[0]?.phoneNumber
            ? stepperData?.partnerValue?.partnerPointOfContact?.[0]?.phoneNumber
            : "--"}
        </p>
        {/* <p className="text-center text-[17px] text-textColor">
          The Indiana Well-Integrated Screening and Evaluation for Women Across
          the Nation (IN-WISEWOMAN) Program addresses the burden of
          cardiovascular disease by identifying and reducing participants' risk
          of having a heart attack, stroke and chronic disease through education
          and early detection screening.
        </p> */}
        <Button className="mt-4" text="Close" onClick={() => openValue()} />
      </div>
    </>
  );
};

export default Step13;

import { toast } from 'react-toastify'

export const successNotify = (text) => {
	toast(text, {
		className: 'toaster-wrapper toaster-wrapper_success',
		bodyClassName: 'toaster-wrapper__content',
	})
}

export const errorNotify = (text) => {
	toast(text, {
		className: 'toaster-wrapper toaster-wrapper_error',
		bodyClassName: 'toaster-wrapper__content',
	})
}

export const warningNotify = (text) => {
	toast(text, {
		className: 'toaster-wrapper toaster-wrapper_warning',
		bodyClassName: 'toaster-wrapper__content',
	})
}

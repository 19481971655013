import React from "react";
import Arrows from "../../assets/images/arrows.png";
import HealthScreening from "../../assets/images/stethoscope-medical-tool.svg";
import BloodPressure from "../../assets/images/BloodPressure.svg";
import TeleMedicine from "../../assets/images/consultation.svg";
import SocialDeterminant from "../../assets/images/houses.svg";

import "./style.scss";

const Modules = () => {
  return (
    <>
      <section id="benefits" className="lg:py-[80px] py-8">
        <div className="max-w-full mx-auto pb-4 text-center mb-3">
          <h2 className="heading md:text-[32px] text-[25px] font-bold text-dimGray max-w-full md:pr-3 pb-2 md:leading-[42px] leading-[30px]">
            WISEWOMAN Participant Benefits
          </h2>
          <p className="paragraph">
            Changing lifestyle behaviors can take time. Little improvements can
            literally add years to your life.
          </p>
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 justify-between gap-4">
          <div>
            <div className="bg-fountainBlue rounded-2xl mb-4 pt-8 p-6 px-5 text-white h-[49%]">
              <div className="h-[50px] w-[50px] rounded-[4px] flex items-center justify-center bg-white p-2">
                <img src={HealthScreening} className="w-[45px]" alt="Icon" />
              </div>
              <h2 className="text-white text-lg font-bold py-3 pb-2 uppercase">
                Health Screenings
              </h2>
              <p className="md:text-[1.1rem] leading-6 ">
                Free integrated medical visit, including cholesterol and glucose
                diagnostics, as well as zero cost breast and cervical
                screenings.
              </p>
            </div>
            <div className="bg-fountainBlue rounded-2xl pt-8 p-6 px-5 text-white h-[49%]">
              <div className="h-[50px] w-[50px] rounded-[4px] flex items-center justify-center bg-white p-2">
                <img src={BloodPressure} className="w-[45px]" alt="Icon" />
              </div>
              <h2 className="text-white text-lg font-bold py-3 pb-2 uppercase">
                Devices to Track Vitals
              </h2>
              <p className="md:text-[1.1rem] leading-6 ">
                Receive Bluetooth or cellular blood pressure monitor to keep
                track of your pressure, as well as share this data with your
                provider to help avoid preventable emergencies and make more
                informed decision about your care.
              </p>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-between lg:max-w-[340px] my-4 lg:my-0 lg:min-h-full min-h-[380px] mx-auto bg-[#A2D59F] w-full rounded-2xl pt-8 pb-0 px-5 text-white h-full">
              <div>
                <h2 className="text-white text-xl font-bold pt-2 pb-1 uppercase">
                  Consultation
                </h2>
                <p className="md:text-[1.1rem] leading-6 ">
                  Become a WISEWOMAN and receive the support, services and
                  resources to live a healthier life.
                </p>
              </div>
              <div className="md:text-[1.3rem] text-[1.2rem] uppercase font-semibold leading-8">
                Easily monitor your health, track your goals and receive program benefits with the CareHalo app
              </div>
              <div className="mobile-bg-img"></div>
            </div>
          </div>
          <div>
            <div className="bg-fountainBlue  rounded-2xl ml-auto mb-4 pt-8 p-6 px-5 text-white lg:mt-0 mt-[35px]">
              <div className="h-[50px] w-[50px] rounded-[4px] flex items-center justify-center bg-white p-2">
                <img src={TeleMedicine} className="w-[45px]" alt="Icon" />
              </div>
              <h2 className="text-white text-lg font-bold py-3 pb-2 uppercase">
                Convenient Telewellness
              </h2>
              <p className="md:text-[1.1rem] leading-6 ">
                Take advantage of health coaching done over the phone. Receive a
                nutritionist consult, diabetic education, recommendation on how
                to lower your blood pressure and so much more.
              </p>
            </div>
            <div className="bg-fountainBlue rounded-2xl ml-auto pt-8 p-6 px-5 text-white">
              <div className="h-[50px] w-[50px] rounded-[4px] flex items-center justify-center bg-white p-2">
                <img
                  cl
                  src={SocialDeterminant}
                  className="w-[45px] fill-fountainBlue"
                  alt="Icon"
                />
              </div>
              <h2 className="text-white text-lg font-bold py-3 pb-2 uppercase">
                Social Determinant Resources
              </h2>
              <p className="md:text-[1.1rem] leading-6 ">
                We recognize there may be a host of challenges impeding your
                access to care and healthier living. WISEWOMAN is dedicated to
                helping participants address those real world issues, whether
                they be a lack transportation, food insecurities, housing, the
                program is committed to helping participants identify the
                resources to overcome those challenges.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Modules;

import React, { useEffect, createContext, useMemo } from "react";
import Button from "../../components/button";
import { useState } from "react";
import { Steps, Progress } from "antd";
import "./style.scss";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import Step11 from "./step11";
import Step12 from "./step12";
import Step13 from "./step13";
import axiosInstance from "../../service/axios";
import CommonErrorMessage from "./commonError";

export const StepperContext = createContext("");

const SelfEnrollment = ({ setOpen }) => {
  const [current, setCurrent] = useState(0);
  const [responseQuestions, setResponseQuestions] = useState([]);

  const [firstStepData, setFirstStepData] = useState({});
 const [isLoading,setIsLoading]=useState(false)
  const [storeQuestionArray, setStoreQuestionArray] = useState([]);
  const [commonValidationError, setCommonValidationError] = useState({
    error: "",
  });
  const [stepperData, setStepperData] = useState({
    commonValidationError,
    setCommonValidationError,
    setCurrent,
    responseQuestions,
    setResponseQuestions,
    setFirstStepData,
    firstStepData,
  });
  let count = 0;
  useEffect(() => {
    setIsLoading(true)
    axiosInstance.get("/enrollQuestions").then(
      (response) => {
        var result = response.data;
        setResponseQuestions(response.data);
        setStepperData((old) => ({
          ...old,
          result,
        }));
        setIsLoading(false)
      },
   

      (error) => {
        setIsLoading(false)
      }
    );
  }, []);
  const next = () => {
    if (current === 0) {
      stepperData.submit();
    } else if (current === 1) {
      stepperData.submit();
    } else if (current === 2) {
      stepperData.submit();
    } else if (current === 3) {
      stepperData.submit();
    } else if (current === 4) {
      stepperData.submit();
    } else if (current === 5) {
      stepperData.submit();
    } else if (current === 7) {
      stepperData.submit(firstStepData);
    } else if (current === 9) {
      stepperData.submit();
    } else if (current === 10) {
      stepperData.submit();
    } else {
      setCurrent((old) => old + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const steps = [
    {
      title: "First",
      content: <Step1  isLoading={isLoading} />,
    },
    {
      title: "Second",
      content: <Step2 />,
    },
    // {
    //   title: "Third",
    //   content: <Step3 />,
    // },
    {
      title: "Fourth",
      content: <Step4 />,
    },
    {
      title: "Fifth",
      content: <Step5 />,
    },
    {
      title: "Sixth",
      content: <Step6 />,
    },
    {
      title: "Seventh",
      content: <Step7 />,
    },
    {
      title: "Eigthth",
      content: (
        <Step8 responseQuestions={responseQuestions} setOpen={setOpen} />
      ),
    },
    {
      title: "Ninth",
      content: <Step9 />,
    },
    {
      title: "Tenght",
      content: <Step10 />,
    },

    {
      title: "Eleventh",
      content: <Step11 />,
    },
    {
      title: "Twelveth",
      content: <Step12 />,
    },
    {
      title: "Thirteenth",
      content: <Step13 setOpen={setOpen} />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const getProfileCompletetionStats = (data = []) => {
    const allStepsCount = responseQuestions.length;
    const completedStepsCount = data.map((item, index) => {
      if (item) {
        count = count + 1;
      }
      return false;
    });

    return {
      allStepsCount,
      completedStepsCount,
    };
  };
  const { allStepsCount } = getProfileCompletetionStats(storeQuestionArray);

  const quotesProviderValue = useMemo(() => {
    return {
      stepperData,
      setStepperData,
      storeQuestionArray,
      setStoreQuestionArray,
    };
  }, [stepperData, setStepperData, storeQuestionArray, setStoreQuestionArray]);
  function openValue() {
    setOpen(false);
  }
  return (
    <>
      {commonValidationError?.error ? (
        <CommonErrorMessage setOpen={setOpen} />
      ) : (
        <StepperContext.Provider value={quotesProviderValue}>
          <Steps current={current} items={items} />
          <div onClick={() => openValue()} className="fixed top-2 right-3 text-white text-[3rem] cursor-pointer flex items-center closeModal z-[999] h-[40px] w-[40px] p-2 pt-2.5 px-[3px]"> &#215;<div className="text-xl content relative">Cancel</div></div>
          <div className="stepper-content">{steps[current].content}</div>

          <div className="mt-0 flex justify-center gap-4">
            {current > 0 &&
              current !== 6 &&
              current !== 11 &&
              current !== 7 && (
                <Button className="secondary" type="primary" onClick={() => prev()} text="Back" />
              )}
            {current < steps.length && current !== 6 && current !== 11 ? (
              <>
                <Button
                  type="button"
                  onClick={() => next()}
                  text={current === 6 ? "Continue" : "Next"}
                  isLoading={stepperData?.isLoading}
                  disabled={
                    stepperData?.isLoading ||
                    (current === 9 && !stepperData?.partnerResponse?.length)
                  }
                />
              </>
            ) : (
              current < steps.length &&
              current === 6 &&
              stepperData.countStepFifth && (
                <>
                  <Button
                    type="button"
                    onClick={() => next()}
                    text={current === 6 ? "Continue" : "Next"}
                    isLoading={stepperData?.isLoading}
                    disabled={
                      stepperData?.submitStepLoading ||
                      stepperData?.isLoading ||
                      (current === 9 && !stepperData?.partnerResponse?.length)
                    }
                  />
                </>
              )
            )}
          </div>
        </StepperContext.Provider>
      )}

      {!commonValidationError?.error && current < 6 && (
        <div className="absolute w-full bottom-4 px-5 mx-2 bg-[#e9f9ff] py-1">
          <Progress percent={(count / allStepsCount) * 100} />
          <div className="text-center">{count}</div>
        </div>
      )}
    </>
  );
};

export default SelfEnrollment;

import React from "react";
import "./style.scss";
import Loader from "../loader";

const Button = ({
  disabled = false,
  className = "",
  onClick,
  reverseIcon,
  icon,
  text,
  isLoading,
}) => {
  return (
    <button
      onClick={onClick}
      className={`py-[10px] border px-[28px] button hover:opacity-60 primary-btn w-max ${className} ${
        disabled
          ? "!bg-veryLightGray !border-veryLightGray cursor-not-allowed"
          : ""
      }`}
      disabled={disabled}
    >
      <div
        className={`flex gap-2 justify-center items-center 
        ${reverseIcon ? "flex-row-reverse" : ""} `}
      >
        {icon ? <img className="w-4" src={icon} alt="icon" /> : ""}
        <div className="whitespace-nowrap lg:text-xl text-lg">
          {isLoading ? (
            <div className="h-7 w-10">
              <Loader />
            </div>
          ) : (
            text
          )}
        </div>
      </div>
    </button>
  );
};

export default Button;

import React, { useState, useEffect } from "react";
import Button from "../../components/button";
import SendIcon from "../../assets/images/send.png";
import { errorNotify, successNotify } from "../../Toaster/toster";
import axiosInstance from "../../service/axios";
import "./style.scss";

const Contact = () => {
  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [message, setMessage] = useState({
    yourName: "",
    email: "",
    description: "",
  });
  const [errorValidationMessage, setErrorValidationMessage] = useState({
    name: "",
    email: "",
  });
  const [errorValidation, setErrorValidation] = useState({
    error: "",
  });
  const handlechange = (e) => {
    const { name, value } = e.target;
    setMessage((old) => ({
      ...old,
      [name]: value,
    }));
    if (!message?.yourName) {
      setErrorValidationMessage((old) => ({
        ...old,
        name: "Name is required",
      }));
    } else {
      setErrorValidationMessage((old) => ({
        ...old,
        name: "",
      }));
    }
   if (message?.email) {
      if (regexEmail.test(message?.email) === false) {
       
        return false;
      } else {
        setErrorValidation((old) => ({
          ...old,
          error: "",
        }));
        setErrorValidationMessage((old) => ({
          ...old,
          name: "",
          email: "",
        }));
        return true;
      }
    }
  };
  const emailValidationTest = () => {
    if (!message?.yourName || !message?.email) {
      errorNotify("Please fill the required fields");
      if (!message?.yourName) {
        setErrorValidationMessage((old) => ({
          ...old,
          name: "Name is required",
        }));
      } else {
        setErrorValidationMessage((old) => ({
          ...old,
          name: "",
        }));
      }

      if (!message?.email) {
        setErrorValidationMessage((old) => ({
          ...old,
          email: "email is required",
        }));
      } else {
        setErrorValidationMessage((old) => ({
          ...old,
          email: "",
        }));
      }
    }
     else if (message?.email) {
      if (regexEmail.test(message?.email) === false) {
        setErrorValidation((old) => ({
          ...old,
          error: "Email should be valid.",
        }));
        return false;
      } else {
        setErrorValidation((old) => ({
          ...old,
          error: "",
        }));
        setErrorValidationMessage((old) => ({
          ...old,
          name: "",
          email: "",
        }));
        return true;
      }
    }
  };
  const submitForm = (e) => {
    e.preventDefault();
    const payload = {
      name: message?.yourName,
      email: message?.email,
      description: message?.description,
    };
    if (emailValidationTest()) {
      axiosInstance.post("/enrolled-patient/contact-us", payload).then(
        async (response) => {
          let result = await response.data;
          successNotify(
            "Thanks for contacting us. We will in touch with you shortly"
          );
          setMessage((old) => ({
            ...old,
            yourName: "",
            email: "",
            description: "",
          }));
        },
        (error) => {
          const errorVal = error?.response?.data;
          errorNotify(error?.response?.data?.message);
        }
      );
    } else {
      console.log("message error");
    }
  };
  useEffect(() => {
    if (errorValidation?.error) {
      errorNotify(errorValidation?.error);
    }
  }, [errorValidation]);
  return (
    <section id="contact" className=" py-5">
      <div className="grid lg:grid-cols-2 lg:gap-0 gap-4 lg:px-10 px-6 lg:py-14 py-8 bg-[#E8F1E8] rounded-xl ">
        <div className="lg:pr-[40px] lg:pb-0 pb-3">
          <img className="lg-w-full w-[68px]" src={SendIcon} alt="Icon" />
          <h2 className="heading md:!text-[2.2rem] font-bold text-dimGray max-w-full md:max-w-[460px]  lg:max-w-[530px] md:pr-3 pb-5 md:leading-[42px] leading-[30px] flex flex-wrap">
            Leave us a message
          </h2>
          <p className="paragraph">Have Questions or Need More Information ?</p>
          <p className="paragraph">We’d love to hear from you.</p>
        </div>
        <div>
          <form className="flex flex-col gap-5">
            <input
              className={`lg:w-auto w-full ${
                errorValidationMessage?.name && "border-themeBrown"
              }`}
              type="text"
              placeholder="Your Name*"
              name="yourName"
              value={message?.yourName}
              autoComplete="off"
              onChange={(e) => handlechange(e)}
            />
            <input
              type="text"
              className={`lg:w-auto w-full ${
                (errorValidationMessage?.email || errorValidation?.error) &&
                "border-themeBrown"
              }`}
              placeholder="Your Email*"
              name="email"
              value={message?.email}
              autoComplete="off"
              onChange={(e) => handlechange(e)}
            />
            <textarea
              type="text"
              className="lg:w-auto w-full"
              placeholder="Message"
              name="description"
              value={message?.description}
              autoComplete="off"
              onChange={(e) => handlechange(e)}
            />
            <Button text="Contact" onClick={(e) => submitForm(e)} />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;

import React from "react";
import BanifitsImage from "../../assets/images/BenifitsSection.png";
import "./style.scss";
import Button from "../../components/button";

const Benefit = ({ setOpen }) => {
  const showDrawer = () => {
    setOpen(true);
  };
  return (
    <section id="" className="flex items-center gap-4 lg:py-20 py-10">
      <div className="">
        <img
          className="max-w-[450px] h-full ml-auto hidden lg:block"
          src={BanifitsImage}
          alt="benefitsImage"
        />
      </div>
      <div className="pl-0 lg:pl-[80px]">
      <h2 className="heading2 font-bold max-w-full md:pr-3 pb-2">
          Who Qualifies?
        </h2>
        <ul className="connection-list pl-2">
          <li className="paragraph">Low Income.</li>
          <li className="paragraph">Uninsured and underinsured </li>
          <li className="paragraph">Women aged 40 to 64 years.</li>
          <li className="paragraph">
            Risk factor (Hypertension, Smoker, Diabetes, Obese, Medical
            History).
          </li>
        </ul>
        <Button
          className="mt-4 uppercase"
          text="Enroll Now"
          onClick={showDrawer}
        />
      </div>
    </section>
  );
};

export default Benefit;

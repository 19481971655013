import React, { useContext, useEffect, useState } from "react";
import { StepperContext } from ".";
import { SelectDropdown } from "../../components/SelectDropdown";
import { feetOption } from "../../const/constants";
import { inchesOption } from "../../const/constants";

const Step4 = (props) => {
  const {
    stepperData,
    setStepperData,
    storeQuestionArray,
    setStoreQuestionArray,
  } = useContext(StepperContext);
  const [questionHeight, setQuestionHeight] = useState(
    stepperData?.result?.[3]
  );

  const [questionWeight, setQuestionWeight] = useState(
    stepperData?.result?.[4]
  );

  const [userWeight, setUserWeight] = useState(stepperData?.userWeight ?? "");

  const [feetInchesValue, setFeetInchesValue] = useState({
    feet: {
      label: stepperData?.feetInchesValue?.feet?.label ?? null,
      value: stepperData?.feetInchesValue?.feet?.value ?? null,
    },
    inches: {
      label: stepperData?.feetInchesValue?.inches?.label ?? null,
      value: stepperData?.feetInchesValue?.inches?.value ?? null,
    },
  });

  const [checkValidation, setCheckValidation] = useState({
    feet: "",
    inches: "",
    weight: "",
  });

  useEffect(() => {
    setQuestionHeight(stepperData?.result?.[3]);
    setQuestionWeight(stepperData?.result?.[4]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepperData?.result?.[3], stepperData?.result?.[4]]);

  const handleChangeForWeight = (e) => {
    const { value } = e.target;
    const valueWeight = value.length == 2 ? "0" + value : value;
    if (!value) {
      setCheckValidation((oldState) => ({
        ...oldState,
        weight: "Please enter weight",
      }));
    } else {
      setCheckValidation((oldState) => ({
        ...oldState,
        weight: "",
      }));
      if (value) {
        if (storeQuestionArray?.find((item) => item.type === "step6")) {
          const data = storeQuestionArray?.map((item) => {
            if (item.type === "step6") {
              return (item = {
                question: questionWeight?.id,
                weight: valueWeight,
                type: "step6",
              });
            } else {
              return item;
            }
          });
          setStoreQuestionArray(data);
        } else {
          setStoreQuestionArray((old) => [
            ...old,
            {
              question: questionWeight?.id,
              weight: valueWeight,
              type: "step6",
            },
          ]);
        }
      } else {
        setStoreQuestionArray((old) =>
          old.filter((item, id) => {
            if (item?.weight != valueWeight) {
              return item;
            }
          })
        );
      }
    }
    if (e.target.value.length > 4) {
      e.preventDefault();
    } else {
      setUserWeight(+value);
    }
  };

  const handleChangeForFeetAndInches = (event, value) => {
    if (!event.value) {
      switch (value.name) {
        case "feet": {
          setCheckValidation((oldState) => ({
            ...oldState,
            [value.name]: "Feet is required",
          }));
          break;
        }
        case "inches": {
          setCheckValidation((oldState) => ({
            ...oldState,
            [value.name]: "Inches is required",
          }));
          break;
        }
      }
    } else {
      setCheckValidation((oldState) => ({
        ...oldState,
        [value.name]: "",
      }));
    }

    setFeetInchesValue((old) => ({
      ...old,
      [value.name]: event,
    }));
  };
  const submitFifthStepData = () => {
    if (!feetInchesValue?.feet?.value) {
      setCheckValidation((oldState) => ({
        ...oldState,
        feet: "Please enter feet",
      }));
    }
    // Start Minutes

    if (!feetInchesValue?.inches?.value) {
      setCheckValidation((oldState) => ({
        ...oldState,
        inches: "Please enter inches",
      }));
    }
    if (!userWeight) {
      setCheckValidation((oldState) => ({
        ...oldState,
        weight: "Please enter weight",
      }));
    } else {
      // Math.round(height * 0.3937
      const convertCms= +(feetInchesValue.feet.value * 12) +
      feetInchesValue.inches.value 
      if (feetInchesValue.feet.value || feetInchesValue.inches.value) {
        if (storeQuestionArray?.find((item) => item.type === "step5")) {
          const data = storeQuestionArray?.map((item) => {
            if (item.type === "step5") {
              return (item = {
                question: questionHeight?.id,
                height:convertCms
                ,
                type: "step5",
              });
            } else {
              return item;
            }
          });
          setStoreQuestionArray(data);
        } else {
          setStoreQuestionArray((old) => [
            ...old,
            {
              question: questionHeight?.id,
              height:convertCms,
               
              type: "step5",
            },
          ]);
        }
      } else {
        setStoreQuestionArray((old) =>
          old.filter((item, id) => {
            if (
              item?.height !=convertCms
             
            ) {
              return item;
            }
          })
        );
      }

      stepperData.setCurrent((old) => old + 1);
    }
  };

  useEffect(() => {
    setStepperData((old) => ({ ...old, submit: submitFifthStepData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValidation, userWeight]);

  useEffect(() => {
    setStepperData((old) => ({ ...old, feetInchesValue, userWeight }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feetInchesValue, userWeight]);
  // BMI
  const bmiCalculator = (data) => {
    let heightvalue=data?.height 
   const bmi=(data?.weight / (heightvalue * heightvalue) * 703).toFixed(1)
    return +bmi;
  };

  let showBMI;

  return (
    <div className="text-center max-w-lg mx-auto h-full flex items-center justify-center flex-col">
      <p className="step-count">
        Step 3
      </p>
      <h2 className="step-question">
        {questionHeight?.question}
      </h2>
      <div className="grid md:grid-cols-2 gap-x-2 gap-y-2 w-full max-w-sm">
        <SelectDropdown
          placeholder="Feets"
          name="feet"
          options={feetOption}
          value={feetInchesValue?.feet}
          errorMessage={checkValidation?.feet}
          onChange={handleChangeForFeetAndInches}
        />
        <SelectDropdown
          placeholder="Inches"
          name="inches"
          options={inchesOption}
          value={feetInchesValue?.inches}
          errorMessage={checkValidation?.inches}
          onChange={handleChangeForFeetAndInches}
        />
      </div>
      <div className="flex flex-col gap-2 mt-6">
        <h2 className="step-question">
          {questionWeight?.question}
        </h2>
        <div className="relative">
          <span className="absolute top-[12px] right-[13px] text-[14px] text-fountainBlue ">
            Lbs
          </span>
          <input
            type="number"
            min={0}
            onChange={(e) => {
              handleChangeForWeight(e);
            }}
            value={userWeight}
            className="w-full"
          />
        </div>
      </div>
      <span className="text-red	 text-sm">{checkValidation?.weight}</span>
      <div className="pt-4 text-fountainBlue font-semibold text-xs">
        <span className="font-bold text-[#000] !text-sm pr-1">Note:</span> Your
        Body Mass Index will be calculated on basis of your feet,inches and
        weigh.
      </div>
      {feetInchesValue.feet.value &&
        feetInchesValue.inches.value &&
        userWeight && (
          <span className=" mt-2 text-fountainBlue font-bold">
            Body Mass Index Total:
            {
              (showBMI = bmiCalculator({
                height:
                  +(feetInchesValue.feet.value * 12) +
                  feetInchesValue.inches.value ,
                weight: userWeight,
              }))
            }
          </span>
        )}
    </div>
  );
};

export default Step4;

import React, { useContext, useState, useEffect } from "react";
import Close from "../../assets/images/closeCircle.svg";
import { StepperContext } from ".";
import { errorNotify } from "../../Toaster/toster";

const Step11 = () => {
  const { stepperData, setStepperData } = useContext(StepperContext);
  const [validationError, setValidationError] = useState({
    checkedValueError: "",
  });

  const [activeItemId, setActiveItemId] = useState(
    stepperData?.activeItemId ?? ""
  );
  const [partnerValue, setPartnerValue] = useState(
    stepperData?.partnerValue ?? ""
  );
  const [providerContactId, setProviderContactId] = useState(
    stepperData?.providerContactId ?? ""
  );
  const handleItemClick = (id, partner, pointOfContact) => {
    setActiveItemId(id === activeItemId ? "" : id);
    setPartnerValue(partner);
    setProviderContactId(pointOfContact);
  };
  const submitEightStepData = () => {
    if (!activeItemId || !stepperData?.partnerResponse?.length) {
      setValidationError((oldState) => ({
        ...oldState,
        checkedValueError: "One Partner should be selected.",
      }));
    } else {
      setValidationError((oldState) => ({
        ...oldState,
        checkedValueError: "",
      }));
      stepperData.setCurrent((old) => old + 1);
    }
  };
  useEffect(() => {
    setStepperData((old) => ({
      ...old,
      activeItemId,
      partnerValue,
      providerContactId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItemId, partnerValue, setPartnerValue, providerContactId]);
  useEffect(() => {
    if (validationError.checkedValueError) {
      errorNotify(validationError.checkedValueError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError]);
  useEffect(() => {
    setStepperData((old) => ({ ...old, submit: submitEightStepData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError, activeItemId]);
  return (
    <div className="w-full">
      <h2 className="text-dimGray md:text-xl text-sm font-semibold pb-2 flex rounded-[4px] pr-1">
        Selected zip code
      </h2>
      <div className="flex gap-6 items-center bg-fountainBlue text-white text-lg font-semibold p-4 py-2 w-max rounded-sm ">
        {stepperData?.searchValue}
        <img
          className="w-5 cursor-pointer"
          src={Close}
          alt=""
          onClick={() => stepperData?.setCurrent((old) => old - 1)}
        />
      </div>
      <h2 className="step-question mt-9 text-center">
        Please select from any of the partners listed
      </h2>
      <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 pr-2 cursor-pointer">
        {stepperData?.partnerResponse?.length > 0
          ? stepperData?.partnerResponse?.map((partner) => {
              return (
                <div
                  className={`border border-[#717171] flex rounded-[4px] text-lg p-[6px] pr-4 ${
                    partner?.id === activeItemId && "bg-fountainBlue text-white"
                  }`}
                  onClick={(event) =>
                    handleItemClick(
                      partner?.id,
                      partner,
                      partner?.partnerPointOfContact
                    )
                  }
                >
                  {partner?.image && (
                    <img className="w-9" src={partner?.image} alt="Logo" />
                  )}
                  <div
                    className={`pl-2 text-[#989898] ${
                      partner?.id === activeItemId && "text-white"
                    }`}
                  >
                    <h3
                      className={`text-[0.84rem] leading-[17px] font-bold pt-1 ${
                        partner?.id === activeItemId && "text-white"
                      }`}
                    >
                      {partner?.companyName}
                    </h3>
                    {partner?.location?.map((address) => {
                      return (
                        <p className="text-[0.8rem] leading-[17px] pt-1">
                          {address?.address?.text}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })
          : ""}
      </div>
      {!(stepperData?.partnerResponse?.length > 0) && (
        <div className="w-full text-center paragraph">
          There is no Available partners for this zip code
        </div>
      )}
    </div>
  );
};

export default Step11;

import React, { useState } from "react";
import "./style.scss";
import Logo from "../../assets/images/logo.svg";
import ToggleNav from "../../assets/images/bars.svg";

const Header = ({setOpen}) => {
  const [collapse, setCollapse] = useState(true);
  const openSideNav = () => {
    setCollapse(!collapse ) 
  };
  return (
    <header className="header">
      <section>
        <div className="flex justify-between w-full">
          <a href="/" class="logo">
            <img src={Logo} alt="wise women" />
          </a>
          <img className={`toggleIcon`} src={ToggleNav} onClick={openSideNav} alt="toggleNavImage"/>
          <div class={`right-nav ${collapse ? "!right-[-200px]": ""}`}>
            <ul class="navigation-list">
             
              <li> <a href="#programs"> Program </a></li>
              {/* <li> <a href="#services"> Services </a></li> */}
              <li> <a href="#benefits"> Benefits </a></li>
              <li> 
                <a href="#enroll" onClick={()=>{
                     setOpen(true)
                }}>  Enroll </a>              
              </li>
              <li> <a href="#contact"> contact  </a></li>
            </ul>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;

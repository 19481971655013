import React, { useState } from "react";
import Header from "./header";
import Banner from "./banner";
import Consultation from "../assets/images/consultation.png";
import Insured from "../assets/images/insured.png";
import Benefits from "../assets/images/benifits.png";
import Footer from "./footer";
import Contact from "./contact";
import Modules from "./modules";
import Enroll from "./enroll";
import Services from "./service";
import Benefit from "./benefits";
import EnrollModal from "./enrollModal";

const Landing = () => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Header setOpen={setOpen} />
      <Banner id="banner" open={open} setOpen={setOpen}/>
      <Enroll open={open} setOpen={setOpen} />
      <section className="grid lg:grid-cols-3 grid-cols-1 py-8 gap-5">
        {[
          [
            Consultation,
            "Consultation icon",
            "NO COST",
            "All services and products provided through WISEWOMAN are at zero cost to participants. ",
          ],
          [
            Insured,
            "Insured icon",
            "CONVENIENT",
            "Enrollment and screenings done close to home and ongoing healthy habits wellness sessions can be done conveniently over the phone.",
          ],
          [
            Benefits,
            "Benefits icon",
            "EFFECTIVE",
            "43.5% of WISEWOMAN participants with known hypertension achieved or are currently maintaining blood pressure control through 2 years of participation. ",
          ],
        ].map(([imageSrc, imageAlt, heading, text]) => (
          <div key={1} className="card p-3">
            <img className="p-2 pr-3 w-[90px] h-[90px]" src={imageSrc} alt={imageAlt} />
            <div className="content">
              <h3 className="font-semibold uppercase pb-1 text-[1.19rem]">{heading}</h3>
              <p className="text-[#989898] text-[1.03rem]">{text}</p>
            </div>
          </div>
        ))}
      </section>
      <Modules />
      <Benefit open={open} setOpen={setOpen} />
      <Services open={open} setOpen={setOpen}/>
      <Contact />
      <Footer  setOpen={setOpen}/>
      {/* <EnrollModal  open={open} setOpen={setOpen} /> */}
    </div>
  );
};

export default Landing;

import React, { useContext, useState, useEffect } from "react";
import axiosInstance from "../../service/axios";
import { StepperContext } from ".";
import { errorNotify } from "../../Toaster/toster";

const Step10 = () => {
  const { stepperData, setStepperData } = useContext(StepperContext);

  const [searchValue, setSearchValue] = useState(
    stepperData?.searchValue ?? ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [partnerResponse, setPartnerResponse] = useState([]);
  const [validationError, setValidationError] = useState({
    validateError: "",
  });

  const handleSearch = (event) => {
    if (!+event.target.value) {
      setValidationError((oldState) => ({
        ...oldState,
        validateError: "Please Enter your zip code",
      }));
    } else {
      setValidationError((oldState) => ({
        ...oldState,
        validateError: "",
      }));
    }
    setSearchValue(event.target.value);
  };
  const getSearchResults = () => {
    setIsLoading(true)
    axiosInstance.get(`partner/allPartner/${searchValue}`).then(
      (response) => {
        var result = response.data;
        setPartnerResponse(result);
        setIsLoading(false)

      },
      (error) => {
        setIsLoading(false)

      }
    );
  };
  useEffect(() => {
    searchValue.toString().length > 4 && getSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  useEffect(() => {
    if (validationError.validateError) {
      errorNotify(validationError.validateError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError]);
  const submitSevenStepData = () => {
    if (!searchValue) {
      setValidationError((oldState) => ({
        ...oldState,
        validateError: "Please Enter your zip code",
      }));
    } else if (searchValue.length < 5 || searchValue.length > 5) {
      setValidationError((oldState) => ({
        ...oldState,
        validateError: "Zip code should be 5 digits",
      }));
    } else {
      setValidationError((oldState) => ({
        ...oldState,
        validateError: "",
      }));
      stepperData.setCurrent((old) => old + 1);
    }
  };
  useEffect(() => {
    setStepperData((old) => ({ ...old, submit: submitSevenStepData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError, searchValue]);
  useEffect(() => {
    setStepperData((old) => ({ ...old, partnerResponse, searchValue,isLoading }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerResponse, stepperData?.searchValue, searchValue,isLoading]);
  return (
    <div className="text-center">
      <h2 className="text-dimGray text-2xl font-semibold pb-2">
        Enter your zip code
      </h2>
      <input
        type={"number"}
        className="border border-dimGray rounded-[4px] w-[300px] !py-[8px]"
        onChange={(e) => handleSearch(e)}
        value={`${searchValue}`}
      />
    </div>
  );
};

export default Step10;

import React from "react";
import "./style.scss";
import Button from "../../components/button";
import Logo from "../../assets/images/logo.svg";
import MainLogo from "../../assets/images/CareHaloLogo.svg";
import PlayStoreIcon from "../../assets/images/play-store.svg";
import AppleIcon from "../../assets/images/apple.svg";

const Footer = ({ setOpen }) => {
  const appleUrl = 'https://apps.apple.com/id/app/mycarehalo/id6452727241';
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.carehaloplusapp&hl=en_IN&gl=US';
  return (
    <footer class="footer w-full z-50 py-8 pt-[40px] lg:mt-8 mt-4">
      <section>
        <div className="flex flex-col gap-4 lg:flex-row w-full py-8 justify-between">
          <div className="flex flex-col justify-between pb-1">
            <div>
              <div class="logo flex gap-3">
                {/* <img
                  className="w-[100px] sm:w-[220px] pb-3"
                  src={Logo}
                  alt="wise women"
                /> */}
                <div>
                  <p className="text-[14px] pt-5 pb-2 leading-3 capitalize text-textColor whitespace-nowrap">
                    In Partnership With
                  </p>
                  <img
                    className=" pb-3 w-[120px] sm:w-[220px]"
                    src={MainLogo}
                    alt="wise women"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex lg:w-[30%] py-4 md:pt-9">
            <div className="w-1/2 ">
              <strong className="text-xl text-dimGray">General</strong>
              <ul className="pt-[15px]">
                <li className="leading-8 text-[#777777] text-md">
                  <a
                    className="hover:text-fountainBlue"
                    href="#enroll"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {" "}
                    Enroll{" "}
                  </a>
                </li>

                <li className="leading-8 text-[#777777] text-md">
                  <a className="hover:text-fountainBlue" href="#contact">
                    {" "}
                    Contact{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-1/2">
              <strong className="text-xl text-dimGray">Main</strong>
              <ul className="pt-[15px]">
                <li className="leading-8 text-[#777777] text-md">
                  <a className="hover:text-fountainBlue" href="#programs">
                    {" "}
                    Program{" "}
                  </a>
                </li>

                {/* <li className="leading-8 text-[#777777] text-md">
                  <a href="#services"> Services </a>
                </li> */}
                <li className="leading-8 text-[#777777] text-md">
                  <a className="hover:text-fountainBlue" href="#benefits">
                    {" "}
                    Benefits{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-fountainBlue rounded-2xl w-full md:w-[450px] p-7 px-5 text-white">
            <h3 className="uppercase font-bold text-xl pb-2 text-white">
              Download the CareHalo App
            </h3>
            <p className="md:text-[1.1rem] leading-6 text-sm">
              The Indian Well-Integrated Screening and Evaluation for Women
              Across the Nation (IN-WISEWOMAN) Program addresses the burden of
              cardiovascular disease.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 pt-[20px]">
              <a
                href={appleUrl}
                target="_blank"
                className="social-icons-link hoverFooterLink"
              >
                <Button
                  icon={AppleIcon}
                  className="uppercase secondary max-sm:w-auto w-[100%]"
                  text="App Store"
                />
              </a>
              <a
                href={playStoreUrl}
                target="_blank"
                className="social-icons-link hoverFooterLink"
              >
                <Button
                  icon={PlayStoreIcon}
                  className="uppercase secondary max-sm:w-auto w-[100%]"
                  text="Play Store"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;

import "./App.css";
import { Route, Routes } from "react-router-dom";
import Landing from "./modules/landing";
import Toaster from "./Toaster";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
      <Toaster/>
    </>
  );
}

export default App;

import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Toaster = () => {
	return (
		<div>
			<ToastContainer className="text-themeBrown" position="bottom-right" hideProgressBar={true} />
		</div>
	)
}

export default Toaster

import axios  from 'axios'

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
})
axiosInstance.interceptors.request.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response.status === 401) {

		}
		//add some logic if needed
		return Promise.reject(error)
	},
)

export default axiosInstance

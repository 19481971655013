import React, { useState, useEffect, useContext } from "react";
import Loader from "../../components/loader";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import PatientLogo from "../../assets/images/patientLogo.svg";
import CalendarIcon from "../../assets/images/calendar.svg";
import NavigationIcon from "../../assets/images/map.svg";
import moment from "moment";
import axiosInstance from "../../service/axios";
import { StepperContext } from ".";
import { errorNotify, successNotify } from "../../Toaster/toster";
import { timeZoneGuess } from "../../utils/time";

const Step12 = () => {
  const { stepperData, setStepperData, storeQuestionArray } =
    useContext(StepperContext);

  const [value, setValue] = useState(new Date().setHours(0, 0, 0, 0));
  const [responseOfSlots, setResponseofSlots] = useState([]);
  const [findDateValue, setFindDateValue] = useState();
  const [activeSlotId, setActiveSlotId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [saveData, setSaveDate] = useState({});
  const [validationError, setValidationError] = useState({
    checkedValueError: "",
  });
  const [submitStepLoading, setSubmitStepLoading] = useState(false);
  // startDate convert unix
  const convertStartUnixDate = (date) => {
    const dateFormat = moment(date).format("YYYY-MM-DD");
    return moment(dateFormat).startOf("day").unix() * 1000;
  };

  const convertDate = (date) => {
    return moment(date).unix() * 1000;
  };
  const unixToDate = (date) => {
    return moment(date).format("MMMM Do YYYY");
  };

  const converTime = (time) => {
    return moment(+time).format("hh:mm A");
  };
  const startDateFirstRender = convertDate(value);
  const endDateDateFirstRender = convertDate(
    moment(startDateFirstRender).endOf("month").format("YYYY-MM-DD")
  );
  const isPrimaryId = [];
  stepperData?.providerContactId?.forEach((provide) => {
    if (provide?.isPrimary) {
      isPrimaryId.push(provide?.provider?.id);
    }
  });
  const idValue = isPrimaryId?.[0] || null;
  const handleDateChange = (date) => {
    setValue(date);
    setSaveDate({});
  };

  useEffect(() => {
    getPartnerSlots(startDateFirstRender, endDateDateFirstRender, idValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemClick = (id, slotStartTime, slotEndTime) => {
    if (isSlotDisabled(slotStartTime)) {
      setActiveSlotId(id === activeSlotId ? "" : id);
      setSaveDate((old) => ({ ...old, slotStartTime, slotEndTime }));
    }
  };
  // New Param for slot Date
  const valueSelectedDate = convertDate(value);
  const valueCalender = convertStartUnixDate(valueSelectedDate);
  const submitNinthStepData = () => {
    if (!activeSlotId) {
      setValidationError((oldState) => ({
        ...oldState,
        checkedValueError: "Atleast one slot should be selected.",
      }));
    } else {
      setValidationError((oldState) => ({
        ...oldState,
        checkedValueError: "",
      }));

      setSubmitStepLoading(true);
      axiosInstance
        .post("/enrolled-patient/external-patient-create", {
          patientEnrolledQuestion: updatedQuestion,
          address: stepperData?.values?.address,
          email: stepperData?.values?.email,
          phone: `+1${stepperData?.values?.phone}`,
          humanName: stepperData?.values?.humanName,
          dateOfBirth: stepperData?.momentDateForLastStep,
          slotBooked: [
            {
              slot: activeSlotId,
              date: valueCalender,
            },
          ],
          gender: "female",
          timezone:timeZoneGuess()
        })
        .then(
          async (response) => {
            let result = await response.data;
            if (result?.status === 200) {
              successNotify("Slot Booked Successfully");
              setSubmitStepLoading(false);

              stepperData.setCurrent((old) => old + 1);
            }
          },
          (error) => {
            setSubmitStepLoading(false);
            errorNotify(error.response.data.message);
          }
        );
    }
  };
  useEffect(() => {
    if (validationError.checkedValueError) {
      errorNotify(validationError.checkedValueError);
    }
  }, [validationError]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setStepperData((old) => ({ ...old, submit: submitNinthStepData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError, activeSlotId]);

  function getPartnerSlots(startDate, endDate, id) {
    axiosInstance
      .get(`enrollQuestions/${id}?startTime=${startDate}&endTime=${endDate}`)
      .then(
        (response) => {
          setResponseofSlots(response?.data);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const handleActiveChange = (date) => {
    const startDate = convertDate(date.activeStartDate);
    const endDate = convertDate(
      moment(startDate).endOf("month").format("YYYY-MM-DD")
    );
    getPartnerSlots(startDate, endDate, idValue);
  };

  // Available Slots

  const disabledFilterDates = responseOfSlots
    ?.filter((avail) => avail?.slots.length)
    ?.map((disabledDate) => {
      return moment(disabledDate.date)._d;
    })
    .map((convertUnix) => {
      return convertUnix.getDate();
    });

  const findDate = async (selectDate) => {
    const getDateSlots = await responseOfSlots?.find((filterDate) => {
      const selectedDate = convertDate(selectDate);
      const valueFilter = convertStartUnixDate(selectedDate);
      return filterDate?.date >= valueFilter;
    });
    setFindDateValue(getDateSlots);
  };
  useEffect(() => {
    setIsLoading(true);
    findDate(value);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseOfSlots, value, findDateValue, isLoading]);

  useEffect(() => {
    setStepperData((old) => ({ ...old, submitStepLoading }));
  }, [submitStepLoading]);

  const updatedQuestion = storeQuestionArray?.map((storeArray) => {
    delete storeArray?.type;
    delete storeArray?.answer;
    delete storeArray?.answerKey;
    return storeArray;
  });
  useEffect(() => {
    setStepperData((old) => ({ ...old, valueAdded: value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  // Slot Time Filter Function
  const data = Date.now();
  let now = moment(data).format("hh:mm:ss A");

  const unixTimestamp = moment(now, "hh:mm:ss A").unix() * 1000;
  const getCurrentTime = (time = new Date()) => {
    const currentTime = moment(time).format("hh:mm A");
    const currentDate = moment().format("MM-DD-YYYY ");
    const newDate = moment(currentDate + " " + currentTime).format(
      "MM-DD-YYYY hh:mm:ss A"
    );
    return moment(newDate).unix() * 1000;
  };
  const isSlotDisabled = (startTime) => {
    if (
      moment(value).startOf("day").format("MM-DD-YYYY hh:mm:ss A") ===
      moment().startOf("day").format("MM-DD-YYYY hh:mm:ss A")
    ) {
      const a = moment(Number(getCurrentTime(Number(startTime)))).diff(
        getCurrentTime(),
        "minutes"
      );
      if (a > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  return (
    <div className="w-full">
      <h2 className="text-dimGray text-lg font-semibold pb-6">
        Schedule your appointment
      </h2>

      <div className="lg:flex pb-3 ml-3 w-full">
        <div>
          <p className="text-dimGray text-sm font-semibold pb-2">
            Select Dates
          </p>
          <Calendar
            value={value}
            onActiveStartDateChange={handleActiveChange}
            showNeighboringMonth={false}
            onChange={handleDateChange}
            tileDisabled={({ date, view }) =>
              !disabledFilterDates.includes(date.getDate())
            }
            minDate={new Date()}
          />
          {/*  */}
        </div>

        <div className="lg:pl-6 pt-4">
          <p className="text-dimGray text-sm font-semibold pb-2">Timings</p>
          <div className="flex flex-wrap gap-3 ">
            {isLoading ? (
              <Loader />
            ) : findDateValue?.slots.length > 0 ? (
              findDateValue?.slots
                // ?.filter((slotFlter) => unixTimestamp < slotFlter?.startTime)
                .map((slot) => {
                  return (
                    <div
                      className={
                        slot?.id === activeSlotId
                          ? `lg:w-[45%] text-center px-2 py-2 min-w-[148px]bg-fountainBlue border rounded bg-fountainBlue  ${
                              isSlotDisabled(slot?.startTime)
                                ? "cursor-pointer"
                                : "cursor-not-allowed"
                            } text-white text-[13px] font-semibold`
                          : `lg:w-[45%] text-center px-2 py-2 min-w-[148px] border-solidGray border rounded bg-[#f9f9f9] bg-opacity-10  ${
                              isSlotDisabled(slot?.startTime)
                                ? "cursor-pointer"
                                : "cursor-not-allowed"
                            } text-teleGrey text-[13px] font-semibold`
                        //  "bg-fountainBlue text-white"
                      }
                      onClick={() =>
                        handleItemClick(
                          slot?.id,
                          slot?.startTime,
                          slot?.endTime
                        )
                      }
                    >
                      {converTime(slot?.startTime)} - {converTime(slot?.endTime)}
                    </div>
                  );
                })
            ) : (
              "No slots available"
            )}
          
          </div>
        </div>
      </div>
      <div>
        <p className="text-dimGray text-sm uppercase font-semibold pb-2 mt-6">
          Selected Appointment
        </p>
        <div className="flex flex-col sm:flex-row gap-4 bg-teleGrey bg-opacity-20 p-4 rounded-lg">
          <img src={PatientLogo} alt="patientLogoImage" />
          <div>
            <h3 className="uppercase text-lg font-bold">
              {stepperData?.partnerValue?.companyName||""}
            </h3>
            {saveData?.slotStartTime && (
              <p className="flex gap-3 pb-1">
                <img
                  className="w-5 h-5"
                  src={CalendarIcon}
                  alt="calendarIconImage"
                />
                <span className="text-[#989898]">
                  {unixToDate(value)} at {converTime(saveData?.slotStartTime)} -
                  {converTime(saveData?.slotEndTime)}
                </span>
              </p>
            )}

            {stepperData?.partnerValue?.location && (
              <p className="flex gap-3">
                <img
                  className="w-5 h-5"
                  src={NavigationIcon}
                  alt="navigationIconImage"
                />
                <span className="text-[#989898]">
                  {stepperData?.partnerValue?.location[0]?.address?.text||""}
                </span>
              </p>
            )}
          </div>
          {submitStepLoading ? <Loader /> : ""}
        </div>
      </div>
    </div>
  );
};

export default Step12;

import React, { useContext, useEffect, useState } from "react";
import { StepperContext } from ".";
import { errorNotify } from "../../Toaster/toster";

const Step2 = () => {
  /**
   * *Context Section
   */
  const {
    stepperData,
    setStepperData,
    storeQuestionArray,
    setStoreQuestionArray,
  } = useContext(StepperContext);
  const [questionsData, setQuestionsData] = useState();
  const [questionsIncome, setQuestionsIncome] = useState();

  const [validationError, setValidationError] = useState({
    validationPeopleLove: "",
    validationMonthlyIncome: "",
  });

  const [saveStepSecondData, setSaveSecondData] = useState({
    peopleLive: stepperData?.saveStepSecondData?.peopleLive ?? null,
    monthlyIncome: stepperData?.saveStepSecondData?.monthlyIncome ?? null,
  });

  function calculateHHI(data) {
    let calData = { base: 14580, factor: 5140 };
    let o = (data.members - 1) * calData.factor + calData.base;
    let l = Math.floor((100 * data.annualIncome) / o);
    return l;
  }

  const submitSecondStepData = () => {
    const HHHCalculation = calculateHHI({
      members: saveStepSecondData?.peopleLive,
      annualIncome: saveStepSecondData?.monthlyIncome * 12,
    });

    if (!saveStepSecondData?.peopleLive || !saveStepSecondData?.monthlyIncome) {
      errorNotify("Please fill the required fields");
    }

    if (!saveStepSecondData?.peopleLive) {
      setValidationError((oldState) => ({
        ...oldState,
        validationPeopleLove: "This field is required.",
      }));
    }
    if (!saveStepSecondData?.monthlyIncome) {
      // monthlyIncome
      setValidationError((oldState) => ({
        ...oldState,
        validationMonthlyIncome: "This field is required.",
      }));
    } else if (HHHCalculation > 250) {
      stepperData.setCommonValidationError((old) => ({
        ...old,
        error: "required Field",
      }));
    } else {
      setValidationError((oldState) => ({
        ...oldState,
        validationMonthlyIncome: "",
      }));
      setValidationError((oldState) => ({
        ...oldState,
        validationPeopleLove: "",
      }));
      stepperData.setCurrent((old) => old + 1);
    }
  };
  // handleChange for people Live
  const handlChangeForPeopleLive = (e) => {
    const { value } = e.target;
    if (!value) {
      setValidationError((oldState) => ({
        ...oldState,
        validationPeopleLove: "This field is required.",
      }));
    } else {
      setValidationError((oldState) => ({
        ...oldState,
        validationPeopleLove: "",
      }));

      if (value) {
        if (storeQuestionArray?.find((item) => item.type === "step2")) {
          const data = storeQuestionArray?.map((item) => {
            if (item.type === "step2") {
              return (item = {
                question: questionsData?.id,
                member: +value,
                type: "step2",
              });
            } else {
              return item;
            }
          });
          setStoreQuestionArray(data);
        } else {
          setStoreQuestionArray((old) => [
            ...old,
            {
              question: questionsData?.id,
              member: +value,
              type: "step2",
            },
          ]);
        }
      } else {
        setStoreQuestionArray((old) => {
          return old.filter((item, id) => {
            if (item?.member !== +value) {
              return item;
            }
          });
        });
      }
    }
    if (e.target.value.length > 3) {
      e.preventDefault();
    } else {
      setSaveSecondData((old) => ({
        ...old,
        peopleLive: value,
      }));
    }
  };
  // handleChange for monthly Income
  const handleChangeForMonthlyIncome = (e) => {
    const { value } = e.target;
    if (!value) {
      setValidationError((oldState) => ({
        ...oldState,
        validationMonthlyIncome: "This field is required.",
      }));
    } else {
      setValidationError((oldState) => ({
        ...oldState,
        validationMonthlyIncome: "",
      }));
      if (value) {
        if (storeQuestionArray?.find((item) => item.type === "step3")) {
          const data = storeQuestionArray?.map((item) => {
            if (item.type === "step3") {
              return (item = {
                question: questionsIncome?.id,
                income: +value,
                type: "step3",
              });
            } else {
              return item;
            }
          });
          setStoreQuestionArray(data);
        } else {
          setStoreQuestionArray((old) => [
            ...old,
            {
              question: questionsIncome?.id,
              income: +value,
              type: "step3",
            },
          ]);
        }
      } else {
        setStoreQuestionArray((old) => {
          return old.filter((item, id) => {
            if (item?.income != +value) {
              return item;
            }
          });
        });
      }
    }
    if (e.target.value.length > 6) {
      e.preventDefault();
    } else {
      setSaveSecondData((old) => ({
        ...old,
        monthlyIncome: value,
      }));
    }
  };
  useEffect(() => {
    setStepperData((old) => ({ ...old, submit: submitSecondStepData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError]);

  useEffect(() => {
    setStepperData((old) => ({ ...old, saveStepSecondData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveStepSecondData]);
  // useEffect(() => {
  //   if (stepperData?.result) {
  //     const incomeQues = stepperData?.result?.find(
  //       (res) => res.question === "Your monthly income?"
  //     );
  //     const quesData = stepperData?.result?.find(
  //       (res) => res.question === "How many people live in your home?"
  //     );
  //     setQuestionsData(quesData);
  //     setQuestionsIncome(incomeQues);
  //   }
  // }, [stepperData?.result]);

  useEffect(() => {
    setQuestionsData(stepperData?.result?.[1]);
    setQuestionsIncome(stepperData?.result?.[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepperData?.result?.[1], stepperData?.result?.[2]]);
  let showDetails;


  return (
     <>
      <div className="text-center max-w-lg mx-auto h-full flex items-center justify-center flex-col">
        <p className="step-count">Step 2</p>

        {questionsIncome && questionsIncome?.max >= "250" ? (
          <>
            {" "}
            <>
              <h2 className="step-question">{questionsData?.question}</h2>
              <input
                type="number"
                onChange={(e) => {
                  handlChangeForPeopleLive(e);
                }}
                value={saveStepSecondData.peopleLive}
                min={0}
              />
              <span className="text-red	 text-sm">
                {validationError?.validationPeopleLove}
              </span>
            </>
            <>
              <h2 className="step-question mt-4">
                {questionsIncome?.question}
              </h2>
              <div className="relative">
                <span className="absolute top-[12px] left-[13px] text-[14px]">
                  $
                </span>
                <input
                  type="number"
                  onChange={(e) => {
                    handleChangeForMonthlyIncome(e);
                  }}
                  value={saveStepSecondData.monthlyIncome}
                  className="!pl-6"
                  min={0}
                />
              </div>
              <span className="text-red	 text-sm">
                {validationError?.validationMonthlyIncome}
              </span>
            </>
          </>
        ) : (
          <>
            {" "}
            <>
              <h2 className="step-question mt-4">
                {questionsIncome?.question}
              </h2>
              <div className="relative">
                <span className="absolute top-[12px] left-[13px] text-[14px]">
                  $
                </span>
                <input
                  type="number"
                  onChange={(e) => {
                    handleChangeForMonthlyIncome(e);
                  }}
                  value={saveStepSecondData.monthlyIncome}
                  className="!pl-6"
                  min={0}
                />
              </div>
              <span className="text-red	 text-sm">
                {validationError?.validationMonthlyIncome}
              </span>
            </>{" "}
            <>
              <h2 className="step-question">{questionsData?.question}</h2>
              <input
                type="number"
                onChange={(e) => {
                  handlChangeForPeopleLive(e);
                }}
                value={saveStepSecondData.peopleLive}
                min={0}
              />
              <span className="text-red	 text-sm">
                {validationError?.validationPeopleLove}
              </span>
            </>
          </>
        )}
        <div className="pt-4 text-fountainBlue font-semibold text-xs">
          <span className="font-bold text-[#000] !text-sm pr-1">Note:</span>
          Your monthly income must be at or below 250% of the Federal Poverty
          Level.
        </div>
        {saveStepSecondData?.peopleLive &&
          saveStepSecondData?.monthlyIncome && (
            <span className=" mt-2 text-fountainBlue font-bold">
              Your Monthly Income is &nbsp;
              {
                (showDetails = calculateHHI({
                  members: +saveStepSecondData?.peopleLive,
                  annualIncome: +saveStepSecondData?.monthlyIncome * 12,
                }))
              }
              % of the Federal Poverty Level.
            </span>
          )}
      </div>
    </>
  );
};

export default Step2;

import moment from "moment-timezone";
export const FORMAT = "MM/DD/YYYY";
export const FORMATFORLASTSTEP = "YYYY-MM-DD";

export const saveDateForFistStep = (item) => {
  return moment(item).format(FORMATFORLASTSTEP);
};
export const convertUnixUTC = (date = toDate()) => {
  return moment.utc(date).unix() * 1000;
};
export const toDate = (date) => {
  return moment(date).toDate();
};
export const timeZoneGuess = () => {
  return moment.tz.guess();
};

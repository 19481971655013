import React from "react";
import ServicesImage from "../../assets/images/services.png";
// import SunIcon from "../../assets/images/sun.svg";
// import EyeIcon from "../../assets/images/eye.svg";
// import FeatherIcon from "../../assets/images/feather.svg";
import "./style.scss";
import Button from "../../components/button";

const Services = ({ setOpen }) => {
  const showDrawer = () => {
    setOpen(true);
  };
  return (
    <section
      id=""
      className="flex gap-4 lg:py-[100px] py-8 pt-[20px] items-center justify-between"
    >
      <div className="flex-col items-center justify-center h-full">
      <h2 className="heading2 font-bold md:pr-3 pb-5">
          Changing lifestyle behaviors can take time. Little improvements can
          literally add years to your life.
        </h2>
        <ul className="connection-list">
          <li className="paragraph">Walking 20 minutes per day.</li>
          <li className="paragraph">
            Reducing the number of cigarettes, you smoke per day.
          </li>
          <li className="paragraph">Lowering calories in your diet.</li>
        </ul>
        <p className="pt-3 text-dimGray text-lg font-semibold">
          We have the support you need.
        </p>
        <Button
          className="mt-2 uppercase"
          text="Enroll Today"
          onClick={showDrawer}
        />
        <p className=" paragraph pt-4 text-[#595959] text-[16px] leading-7">
          Having hypertension puts you at risk for &nbsp;
          <a
            rel="noreferrer"
            target="_blank"
            className="cursor-pointer text-fountainBlue"
            href="https://www.cdc.gov/heartdisease/"
          >
            heart disease 
          </a>
          &nbsp; and &nbsp;
          <a
           rel="noreferrer"
            target="_blank"
            className="cursor-pointer text-fountainBlue"
            href="https://www.cdc.gov/stroke/"
          >
            stroke
          </a>
          , which are leading causes of death in the United States. Enroll in
          WISEWOMAN to reduce your risk and improve your health.
        </p>
        
      </div>
      <div className="hidden lg:block w-full">
        <img
          className="w-[400px] ml-auto"
          src={ServicesImage}
          alt="serviceImage"
        />
      </div>
    </section>
  );
};

export default Services;

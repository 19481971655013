import React, { useContext, useEffect } from "react";
import { StepperContext } from ".";
import * as yup from "yup";
import { useFormik } from "formik";
import { errorNotify } from "../../Toaster/toster";
import axiosInstance from "../../service/axios";

const Step9 = () => {
  const { stepperData, setStepperData } = useContext(StepperContext);

  const initialValues = {
    address: {
      state: "",
      city: "",
      postalCode: "",
      line: "",
      line1: "",
    },
    humanName: {
      text: "",
      given: "",
      family: "",
    },
    phone: "",
    email: "",
  };

  const validationSchema = yup.object().shape({
    phone: yup
      .string()
      .required("Phone number is required")
      .min(10, "Phone number should be atleast 10 digits.")
      .max(10, "Phone number should not be max 10 digits.")
      .matches(/^[0-9\.\+\/]+$/, "Please enter valid the phone number."),
    email: yup
      .string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Your email is invalid."
      )
      .email()
      .required("Email number is required"),
    address: yup.object().shape({
      state: yup.string().required("State is required"),
      city: yup.string().required("City is required"),
      postalCode: yup
        .string()
        .required("Zip code is required")
        .min(5, "Zip code should be 5 digits.")
        .max(5, "Zip code should be 5 digits."),
      line: yup.string().required("Line is required"),
    }),
    humanName: yup.object().shape({
      given: yup.string().required("First name is required"),
      family: yup.string().required("Last name is required"),
    }),
  });
  const onSubmitFn = async (values, actions) => {
    stepperData.setCurrent((old) => old + 1);
  };
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmitFn,
  });
  const validateForm = (values) => {
    validationSchema.isValid(values).then((valid) => {
      if (!valid) {
        errorNotify("Please Fill all the required fields.");
      }
    });
  };

  const onNextClick = (values) => {
    validateForm(values);
    handleSubmit();
  };
  useEffect(() => {
    setStepperData((old) => ({ ...old, submit: onNextClick }));
            // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  useEffect(() => {
    stepperData.setFirstStepData({
      ...values,
    });

    setStepperData((old) => ({
      ...old,
      values,
      isSubmitting,
      searchValue: values?.address?.postalCode,
    }));
            // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [values, values?.address?.postalCode]);

  useEffect(() => {
    if (stepperData?.values) {
      setFieldValue("email", stepperData?.values?.email);
      setFieldValue("phone", stepperData?.values?.phone);
      setFieldValue("address.state", stepperData?.values?.address?.state);
      setFieldValue("address.city", stepperData?.values?.address?.city);
      setFieldValue(
        "address.postalCode",
        stepperData?.values?.address?.postalCode
      );
      setFieldValue("address.line", stepperData?.values?.address?.line);
      setFieldValue("address.line1", stepperData?.values?.address?.line1);
      setFieldValue("humanName.given", stepperData?.values?.humanName?.given);
      setFieldValue("humanName.family", stepperData?.values?.humanName?.family);
    } else {
      console.log("null", null);
    }
  }, []);

  const postalCode = () => {
    if (values?.address?.postalCode?.toString()?.length === 5) {
      axiosInstance
        .get(
          `addresses/zip-codes-external?zipCode=${values?.address?.postalCode}`
        )
        .then(
          (response) => {
            var result = response.data[0];
            setFieldValue("address.state", result?.state||stepperData?.values?.address?.state);
            setFieldValue("address.city", result?.primaryCity||stepperData?.values?.address?.city);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };
  useEffect(() => {
    postalCode();
  }, [values?.address?.postalCode]);
  return (
    <div className="w-full">
      <h2 className="step-question !text-center">
        Almost done
      </h2>
      <form className="grid sm:grid-cols-2 gap-6 pr-2 md:mx-5" onSubmit={handleSubmit}>
        <div>
          <input
            placeholder="First Name"
            name={"humanName.given"}
            value={values?.humanName?.given}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
          />

          {touched?.humanName?.given && errors?.humanName?.given && (
            <span className="text-red">{errors?.humanName?.given}</span>
          )}
        </div>
        <div>
          <input
            placeholder="Last Name"
            name={"humanName.family"}
            value={values?.humanName.family}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched?.humanName?.family && errors?.humanName?.family && (
            <span className="text-red">{errors?.humanName?.family}</span>
          )}
        </div>
        <div className="relative">
          <span className="absolute top-[9px] left-[9px] z-[99] bg-white p-1">
            +1
          </span>
          <input
            className="!pl-9"
            placeholder="Phone No"
            name="phone"
            type="number"
            value={`${values?.phone}`}
            onChange={(e) => {
              if (e.target.value.length > 10) {
                e.preventDefault();
              } else {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
          />

          {touched?.phone && errors?.phone && (
            <span className="text-red">{errors?.phone}</span>
          )}
        </div>
        <div>
          <input
            placeholder="Email"
            name="email"
            onChange={(e) => {
              handleChange(e);
            }}
            value={values?.email}
            onBlur={handleBlur}
          />
          {touched?.email && errors?.email && (
            <span className="text-red">{errors?.email}</span>
          )}
        </div>
        <div>
          <input
            placeholder="Address Line 1"
            name={"address.line"}
            value={values?.address?.line}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched?.address?.line && errors?.address?.line && (
            <span className="text-red">{errors?.address?.line}</span>
          )}
        </div>
        <div>
          <input
            placeholder="Address Line 2"
            name={"address.line1"}
            value={values?.address?.line1}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <div>
          <input
            type="number"
            placeholder="Zip code"
            name={"address.postalCode"}
            maxLength={5?.toString}
            value={values?.address?.postalCode}
            onChange={(e) => {
              if (e.target.value.length > 5) {
                e.preventDefault();
              } else {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
          />
          {touched?.address?.postalCode && errors?.address?.postalCode && (
            <span className="text-red">{errors?.address?.postalCode}</span>
          )}
        </div>
        <div>
          <input
            placeholder="State"
            value={values?.address?.state}
            name={"address.state"}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched?.address?.state && errors?.address?.state && (
            <span className="text-red">{errors?.address?.state}</span>
          )}
        </div>
        <div>
          <input
            placeholder="City"
            value={values?.address?.city}
            name={"address.city"}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          {touched?.address?.city && errors?.address?.city && (
            <span className="text-red">{errors?.address?.city}</span>
          )}
        </div>
      </form>
    </div>
  );
};

export default Step9;

import React from "react";
import Drawer from "../../components/drawer";
import Button from "../../components/button";
import SelfEnrollment from "../selfEnrollment";
import BannerRightImage from "../../assets/images/bannerRightImage.png";
import "./style.scss";

const Banner = ({ open, setOpen }) => {
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <section>
      <div className="flex md:flex-row flex-col-reverse  mx-auto justify-between md:pt-[120px] pt-[90px]">
        <div className="flex flex-col justify-center md:pr-5 max-w-full md:max-w-[480px]  lg:max-w-[550px]">
          <h1 className="heading md:text-[32px] text-[25px] font-bold text-dimGray max-w-full md:max-w-[460px]  lg:max-w-[530px] md:pr-3 pb-5 md:leading-[42px] leading-[30px] flex flex-wrap">
            FREE Heart Health Program
          </h1>
          <p className="paragraph">
            Benefit from FREE medical screenings, labs and health resources to
            help manage your high blood pressure and hypertension.
          </p>
          <div className="flex gap-4 mt-6">
            <Button type="primary" onClick={showDrawer} text={"Enroll Now"} />
         
            <Button type="primary" onClick={showDrawer} text={"Do I Quality?"} />
        
           
            {/* <Button
              reverseIcon
              icon={PlayIcon}
              className="secondary"
              text="Watch Demo"
            /> */}
          </div>
        </div>
        <div className="right-bg">
          <img
            className="md:max-w-full mx-auto max-w-[60%]"
            src={BannerRightImage}
            alt="bannerRightImage"
          />
        </div>
        {open && (
          <Drawer
            placement="right"
            onClose={onClose}
            open={open}
            content={<SelfEnrollment setOpen={setOpen} />}
            maskClosable={false}
            forceRender={true}
            // keyboard={true}
          />
        )}
      </div>
    </section>
  );
};

export default Banner;

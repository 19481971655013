import { Card } from "antd";
import React from "react";
import CardImage from "../../assets/images/enrolment.png";
import "./style.scss";
import Button from "../../components/button";

const Enroll = (props) => {
  const showDrawer = () => {
    props.setOpen((old) => !old);
  };
  return (
    <section
      id="programs"
      className="flex flex-col lg:flex-row gap-4 lg:py-[100px] py-14 lg:items-center items-start"
    >
      <div className="card-animations relative max-w-[390px] p-2 w-full">
        <div className="div1 h-12 w-12 rounded-[50%] bg-fountainBlue absolute left-[-10px] top-[-10px] z-20" />
        <div className=" div2 h-12 w-12 blur-[18px] rounded-[50%] bg-fountainBlue absolute right-[-6px] top-[-6px]" />
        <div className="div3 h-7 w-7 rounded-[50%] bg-green absolute left-[-7px] bottom-[20px]" />
        <div className=" div4 h-7 w-7 rounded-[50%] bg-fountainBlue absolute right-[-7px] bottom-[10px] opacity-[0.4]" />

        <Card
          hoverable
          cover={<img className="rounded-md" alt="example" src={CardImage} />}
        >
          <h2 className="uppercase text-[#595959] text-xl font-semibold pb-1">
            Did You Know?
          </h2>
          <p className="paragraph !text-[1rem]">
            Hypertension is known as the “silent killer”. It affects nearly half
            of all adults in the U.S. and accounts for 1 in every 4 deaths.
          </p>
        </Card>
      </div>
      <div className="lg:pl-[100px] lg:pt-3 pl-1 flex-col items-center justify-center h-full w-full">
        <h2 className="heading2 font-bold text-dimGray max-w-full md:pr-3 pb-5">
          WISEWOMAN gives you the services, tools and resources for healthier
          living.
        </h2>
        <p class="paragraph pt-3 text-[#595959] text-[16px] leading-7">The CDC funded <span class="font-semibold"> WISEWOMAN</span> (<span class="font-semibold text-fountainBlue">W</span>ell-<span class="font-semibold text-fountainBlue">I</span>ntegrated&nbsp;<span class="font-semibold text-fountainBlue">S</span>creening and&nbsp;<span class="font-semibold text-fountainBlue">E</span>valuation for&nbsp;<span class="font-semibold text-fountainBlue">WOM</span>en&nbsp;<span class="font-semibold text-fountainBlue">A</span>cross the&nbsp;<span class="font-semibold text-fountainBlue">N</span>ation) program helps qualified women understand and reduce their risk for heart disease and stroke by providing FREE services to promote lasting heart-healthy lifestyles, devices and technology to assist with management and access to care, resources to foster healthier living.</p>
        <p>&nbsp;</p>
        <Button text="Enroll Now" onClick={showDrawer} />
      </div>
    </section>
  );
};

export default Enroll;

import React, { useContext, useEffect, useState } from "react";
import { StepperContext } from ".";
import { errorNotify } from "../../Toaster/toster";

const Step5 = (props) => {
  const {
    stepperData,
    setStepperData,
    storeQuestionArray,
    setStoreQuestionArray,
  } = useContext(StepperContext);
  const [questionsDataSeventhStep, setQuestionsDataSeventhStep] = useState(
    stepperData?.result?.[5]
  );
  const [checkedValue, setCheckedValue] = useState(
    stepperData?.result?.[5]?.enrollQuestion
  );
  const [validationError, setValidationError] = useState({
    checkedValueError: "",
  });
  useEffect(() => {
    setQuestionsDataSeventhStep(stepperData?.result?.[5]);
    setCheckedValue(stepperData?.result?.[5]?.enrollQuestion);
            // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [stepperData?.result?.[5]?.enrollQuestion, stepperData?.result?.[5]]);

  const handleChange = (e, value, answerKey) => {
    const { checked } = e.target;
    if (checked) {
      if (storeQuestionArray?.find((item) => item.type === "step7")) {
        const data = storeQuestionArray?.map((item) => {
          if (item.type === "step7") {
            return (item = {
              question: questionsDataSeventhStep?.id,
              option: value,
              answer: answerKey,

              type: "step7",
            });
          } else {
            return item;
          }
        });
        setStoreQuestionArray(data);
      } else {
        setStoreQuestionArray((old) => [
          ...old,
          {
            question: questionsDataSeventhStep?.id,
            option: value,
            answer: answerKey,
            type: "step7",
          },
        ]);
      }
    } else {
      setStoreQuestionArray((old) =>{
       return old.filter((item, id) => {
          if (item?.option !== value) {
            return item;
          }
        })
    });
    }

    checkedValue?.map((user) => {
      if (user.id === value) {
        user.isChecked = checked;
        return user;
      } else {
        checked ? (user.isChecked = !checked) : (user.isChecked = checked);
        return user;
      }
    });

    if (!checked) {
      setValidationError((oldState) => ({
        ...oldState,
        checkedValueError: "Atleast one answer should be selected",
      }));
    } else {
      setValidationError((oldState) => ({
        ...oldState,
        checkedValueError: "",
      }));
    }
  };

  const submitSeventhStepData = () => {
    const CheckedAnswered = checkedValue?.find(
      (checkVal) => checkVal?.isChecked
    );
    if (!CheckedAnswered?.isChecked) {
      setValidationError((oldState) => ({
        ...oldState,
        checkedValueError: "Atleast one answer should be selected",
      }));
    } else {
      stepperData.setCurrent((old) => old + 1);
    }
  };

  useEffect(() => {
    setStepperData((old) => ({ ...old, submit: submitSeventhStepData }));
            // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [validationError, checkedValue]);
  useEffect(() => {
    if (validationError.checkedValueError) {
      errorNotify(validationError.checkedValueError);
    }
            // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [validationError, setValidationError]);

  return (
    <div className="text-center max-w-lg mx-auto h-full flex items-center justify-center flex-col">
      <p className="step-count">
        Step 4
      </p>
      <h2 className="step-question">
        {questionsDataSeventhStep?.question}
      </h2>
      <div className="grid grid-cols-2 gap-5 pt-4">
        {checkedValue?.map((item) => {
          return (
            <div
              className={`uppercase relative py-[6px] px-5 border rounded min-w-[120px] cursor-pointer text-lg ${
                item?.isChecked
                  ? " text-fountainBlue font-bold"
                  : "border-solidGray"
              }`}
            >
              {item?.enrollQuestionsOptions}
              <input
                checked={item?.isChecked}
                id={item?.id}
                aria-describedby="helper-checkbox-text"
                type="checkbox"
                className="absolute opacity-0 top-0 left-0 cursor-pointer  w-full h-full text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onChange={(e) => {
                  handleChange(e, item?.id, item?.isAnswer);
                }}
              />
            </div>
          );
        })}
       
      </div>
    </div>
  );
};

export default Step5;

import React, { useContext, useEffect, useState } from "react";
import { StepperContext } from ".";
import { errorNotify } from "../../Toaster/toster";
import dayjs from "dayjs";
import moment from "moment";
import { DatePicker, Space } from "antd";
import { FORMAT, saveDateForFistStep } from "../../utils/time";
import Loader from "../../components/loader";

const Step1 = ({isLoading}) => {
  /**
   * *Context Section
   */
  const {
    stepperData,
    setStepperData,
    storeQuestionArray,
    setStoreQuestionArray,
  } = useContext(StepperContext);
  /**
   * *State Section
   */
  const [firstStepQuestion, setFirstStepQuestion] = useState(
    stepperData?.result?.[0]
  );

  const [validationError, setValidationError] = useState({
    dateValueError: "",
  });

  const [convertAgeDate, setConvertAgeDate] = useState(
    stepperData?.convertAgeDate ?? ""
  );

  const [displaySelectedDate, setDisplaySelectedDate] = useState();

  const [saveDateForLastStep, setSaveDateLastStep] = useState();
  /**
   * *Variables Section
   */
  const momentDateForLastStep = saveDateForFistStep(saveDateForLastStep);
  /**
   * *Function Section
   */
  const convertAgeYear = (value, dateString) => {
    if (!dateString) {
      setConvertAgeDate("");
      setValidationError((oldState) => ({
        ...oldState,
        dateValueError: "Please select your date of birth.",
      }));
    } else {
      setConvertAgeDate(dayjs(dateString, FORMAT));
      setValidationError((oldState) => ({
        ...oldState,
        dateValueError: "",
      }));
    }
    let today = new Date();
    let birthDate = new Date(value);
    let age_now = today?.getFullYear() - birthDate?.getFullYear();
    let m = today?.getMonth() - birthDate?.getMonth();
    if (m < 0 || (m === 0 && today?.getDate() < birthDate?.getDate())) {
      age_now--;
    }

    setDisplaySelectedDate(age_now);
    setSaveDateLastStep(dateString);
    if (dateString) {
      if (storeQuestionArray?.find((item) => item.type === "step1")) {
        const data = storeQuestionArray?.map((item) => {
          if (item.type === "step1") {
            return (item = {
              question: firstStepQuestion?.id,
              age: age_now,
              type: "step1",
            });
          } else {
            return item;
          }
        });
        setStoreQuestionArray(data);
      } else {
        setStoreQuestionArray((old) => [
          ...old,
          {
            question: firstStepQuestion?.id,
            age: age_now,
            type: "step1",
          },
        ]);
      }
    } else {
      setStoreQuestionArray((old) => {
        return old.filter((item, id) => {
          if (item?.age !== age_now) {
            return item;
          }
        });
      });
    }

    return age_now;
  };
  const submitFirstStepData = () => {
    if (!convertAgeDate) {
      setValidationError((oldState) => ({
        ...oldState,
        dateValueError: "Please select your date of birth.",
      }));
    } else if (displaySelectedDate >= firstStepQuestion?.max) {
      stepperData.setCommonValidationError((old) => ({
        ...old,
        error: "required Field",
      }));
    } else if (displaySelectedDate < firstStepQuestion?.min) {
      stepperData.setCommonValidationError((old) => ({
        ...old,
        error: "required Field",
      }));
    } else {
      stepperData.setCurrent((old) => old + 1);
    }
  };

  function disabledYear(current) {
    return current > moment().toDate();
  }
  /**
   * *useEffects Section
   * @param useEffectName
   */
  useEffect(() => {
    setFirstStepQuestion(stepperData?.result?.[0]);
  }, [stepperData?.result]);

  useEffect(() => {
    setStepperData((old) => ({
      ...old,
      convertAgeDate,
      momentDateForLastStep,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertAgeDate]);

  useEffect(() => {
    setStepperData((old) => ({ ...old, submit: submitFirstStepData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationError, displaySelectedDate, setStepperData]);

  useEffect(() => {
    if (validationError.dateValueError) {
      errorNotify(validationError.dateValueError);
    }
  }, [validationError]);
  /**
   * *JSX Section
   * @returns Step 1 DatePicker Form
   */
  return (
    <>
 
      <div className="absolute top-0 text-center w-full pt-11 left-0">
        <h2 className="md:!text-[35px] !text-[30px] font-bold heading">
          Great Decision to Enroll!
        </h2>
        <p className="paragraph font-semibold !text-lg">
          Just a few Quick Questions to Determine Your Qualification.
        </p>
      </div>
      <div className="text-center max-w-lg mx-auto h-full flex items-center justify-center flex-col">
        <p className="step-count">Step 1</p>
       {isLoading ? <Loader/>: 
        <><h2 className="step-question">{firstStepQuestion?.question}</h2><Space direction="vertical">
            <DatePicker
              className="w-[270px] mt-2"
              placement="bottomRight"
              format={FORMAT}
              onChange={convertAgeYear}
              value={convertAgeDate}
              disabledDate={disabledYear}
              placeholder="mm/dd/yyyy" />
          </Space></>
}
        <div className="pt-4 text-fountainBlue font-semibold text-[1rem] break-words px-2">
          <span className="font-bold text-[#000] !text-lg pr-1">Note:</span>
          Your eligibility criteria should lies between 40 to 64.
        </div>
      </div>
    </>
  );
};

export default Step1;

import React from "react";
import Select from "react-select";
import "./style.scss";

const colourStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // make sure the portal is above other elements
    height: "auto",
    position: "absolute",
    top: 0,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // make sure the menu is above other elements
  }),
};

export function SelectDropdown({
  options,
  name,
  placeholder,
  className,
  value,
  onChange,
  errorMessage,
}) {
  return (
    <div className="w-full">
      <Select
        styles={colourStyles}
        maxMenuHeight={140}
        options={options}
        name={name}
        value={value?.value ? value : null}
        className={errorMessage ? "custom-select error" : "custom-select"}
        placeholder={placeholder}
        onChange={onChange}
      />
      <span className="text-red	 text-sm">{errorMessage}</span>
    </div>
  );
}

import ErrorImage from "../../assets/images/newError.gif";
import React from "react";
import Button from "../../components/button";
const CommonErrorMessage = ({ setOpen }) => {
  function openValue() {
    setOpen(false);
  }
  return (
    <div className="text-center">
      <p className="text-textColor text-[1.2rem] font-medium pb-4 leading-[1.65rem]">
        Based on the information you provided, you currently do not meet the
        qualifications for WISEWOMAN. However, the state has a number of
        programs available that may be able to provide health services at no
        cost to you. For more information, please
        <a
          href="#contact"
          className="bg-sky-600 underline text-fountainBlue pl-1"
          onClick={() => openValue()}
        >
          leave us a message.
        </a>
      </p>
      <Button text="Close" onClick={() => openValue()} />
    </div>
  );
};

export default CommonErrorMessage;
